import { ReactComponent as Aggregation } from '@svg/aggregation.svg';
import { ReactComponent as BookIcon } from '@svg/book.svg';
import { ReactComponent as EmbeddingCustomModelIcon } from '@svg/embedding-custom-model.svg';
import { ReactComponent as EmbeddingModelIcon } from '@svg/embedding-model.svg';
import { ReactComponent as SummaryViewIcon } from '@svg/summaryView.svg';
import React from 'react';
import { useTectonTheme } from '../Theme/ThemeProvider';
import AccountsAndAccess from './AccountsAndAccess';
import ArrowUpRight from './ArrowUpRight';
import Calendar from './Calendar';
import Checkmark from './Checkmark';
import ChevronDown from './ChevronDown';
import ChevronRight from './ChevronRight';
import CloseSmall from './CloseSmall';
import CodeChanges from './CodeChanges';
import Columns from './Columns';
import Compute from './Compute';
import Copy from './Copy';
import Danger from './Danger';
import DataQuality from './DataQuality';
import Dataflow from './Dataflow';
import Dataset from './Dataset';
import DefaultServerGroup from './DefaultServerGroup';
import Documentation from './Documentation';
import DrawerHide from './DrawerHide';
import DrawerShow from './DrawerShow';
import Edit from './Edit';
import Entities from './Entities';
import Exit from './Exit';
import Eye from './Eye';
import FeatureService from './FeatureService';
import FeatureServerGroup from './FeatureServerGroup';
import FeatureView from './FeatureView';
import Filters from './Filters';
import Folder from './Folder';
import Group from './Group';
import Information from './Information';
import Jobs from './Jobs';
import KeyChain from './KeyChain';
import LightBulb from './LightBulb';
import LogOut from './LogOut';
import Logo from './Logo';
import MagnifyingGlass from './MagnifyingGlass';
import Monitoring from './Monitoring';
import PermissionsIcon from './PermissionsIcon';
import Robot from './Robot';
import SelectedArrow from './SelectedArrow';
import Sources from './Sources';
import Support from './Support';
import Tag from './Tag';
import Transformations from './Transformations';
import TransformServerGroup from './TransformServerGroup';
import Trash from './Trash';
import Warning from './Warning';
import WhatsNew from './WhatsNew';
import Wordmark from './Wordmark';
import Workspaces from './Workspaces';

export const useIconColors = () => {
  const { theme } = useTectonTheme();

  const IconColorMap: Record<IconTypes, string> = {
    [IconTypes.ACCOUNTS_AND_ACCESS]: theme.colors.primary,
    [IconTypes.ARROW_UP_RIGHT]: theme.colors.primary,
    [IconTypes.CALENDAR]: theme.colors.primary,
    [IconTypes.CHECKMARK]: theme.colors.success,
    [IconTypes.CHEVRON_DOWN]: theme.colors.primary,
    [IconTypes.CHEVRON_RIGHT]: theme.colors.primary,
    [IconTypes.CLOSE_SMALL]: theme.colors.primary,
    [IconTypes.CODE_CHANGES]: theme.colors.primary,
    [IconTypes.COLUMNS]: theme.colors.primary,
    [IconTypes.COMPUTE]: theme.colors.primary,
    [IconTypes.COPY]: theme.colors.primary,
    [IconTypes.DANGER]: theme.colors.dangerText,
    [IconTypes.DOCUMENTATION]: theme.colors.primary,
    [IconTypes.DATAFLOW]: theme.colors.primary,
    [IconTypes.DATA_QUALITY]: theme.colors.primary,
    [IconTypes.DATASET]: theme.FCOColors.dataset,
    [IconTypes.DEFAULT_SERVER_GROUP]: theme.colors.primary,
    [IconTypes.DRAWER_HIDE]: theme.colors.primary,
    [IconTypes.DRAWER_SHOW]: theme.colors.primary,
    [IconTypes.EDIT]: theme.colors.primary,
    [IconTypes.ENTITIES]: theme.FCOColors.entity,
    [IconTypes.EYE]: theme.colors.primary,
    [IconTypes.FEATURE_VIEWS]: theme.FCOColors.featureView,
    [IconTypes.FEATURE_SERVICE]: theme.FCOColors.featureService,
    [IconTypes.FEATURE_SERVER_GROUP]: theme.colors.primary,
    [IconTypes.FILTERS]: theme.colors.primary,
    [IconTypes.FOLDER]: theme.colors.primary,
    [IconTypes.GROUP]: theme.colors.primary,
    [IconTypes.INFORMATION]: theme.colors.primary,
    [IconTypes.KEY_CHAIN]: theme.colors.primary,
    [IconTypes.JOBS]: theme.colors.primary,
    [IconTypes.LOGO]: theme.colors.primary,
    [IconTypes.LOGOUT]: theme.colors.primary,
    [IconTypes.MAGNIFYING_GLASS]: theme.colors.primary,
    [IconTypes.MONITORING]: theme.colors.primary,
    [IconTypes.PERMISSIONS]: theme.colors.primary,
    [IconTypes.ROBOT]: theme.colors.primary,
    [IconTypes.SOURCES]: theme.FCOColors.source,
    [IconTypes.SUMMARY]: theme.colors.primary,
    [IconTypes.SUPPORT]: theme.colors.primary,
    [IconTypes.TAG]: theme.colors.primary,
    [IconTypes.TRANSFORMATIONS]: theme.FCOColors.transformation,
    [IconTypes.TRANSFORM_SERVER_GROUP]: theme.colors.primary,
    [IconTypes.TRASH]: theme.colors.primary,
    [IconTypes.WARNING]: theme.colors.warning,
    [IconTypes.WHATS_NEW]: theme.colors.primary,
    [IconTypes.WORDMARK]: theme.colors.primary,
    [IconTypes.WORKSPACES]: theme.colors.primary,
    [IconTypes.EXIT]: theme.colors.primary,
    [IconTypes.EMBEDDING_MODEL]: theme.colors.primary,
    [IconTypes.EMBEDDING_CUSTOM_MODEL]: theme.colors.primary,
    [IconTypes.BOOK]: theme.colors.primary,
    [IconTypes.AGGREGATION]: theme.colors.primary,
    [IconTypes.SELECTED_ARROW]: theme.colors.primary,
    [IconTypes.LIGHTBULB]: theme.colors.primary,
  };

  return IconColorMap;
};

export enum IconTypes {
  ACCOUNTS_AND_ACCESS,
  ARROW_UP_RIGHT,
  BOOK,
  CALENDAR,
  CHECKMARK,
  CHEVRON_DOWN,
  CHEVRON_RIGHT,
  CLOSE_SMALL,
  CODE_CHANGES,
  COLUMNS,
  COMPUTE,
  COPY,
  DANGER,
  DATAFLOW,
  DATA_QUALITY,
  DATASET,
  DOCUMENTATION,
  DEFAULT_SERVER_GROUP,
  DRAWER_HIDE,
  DRAWER_SHOW,
  EDIT,
  ENTITIES,
  EYE,
  FEATURE_VIEWS,
  FEATURE_SERVICE,
  FEATURE_SERVER_GROUP,
  FILTERS,
  FOLDER,
  GROUP,
  INFORMATION,
  KEY_CHAIN,
  JOBS,
  LIGHTBULB,
  LOGO,
  LOGOUT,
  MAGNIFYING_GLASS,
  MONITORING,
  PERMISSIONS,
  ROBOT,
  SOURCES,
  SUMMARY,
  SUPPORT,
  TAG,
  TRANSFORMATIONS,
  TRANSFORM_SERVER_GROUP,
  TRASH,
  WARNING,
  WHATS_NEW,
  WORKSPACES,
  WORDMARK,
  EXIT,
  EMBEDDING_MODEL,
  EMBEDDING_CUSTOM_MODEL,
  AGGREGATION,
  SELECTED_ARROW,
}

export const Icons: Record<IconTypes, React.JSX.Element> = {
  [IconTypes.ACCOUNTS_AND_ACCESS]: AccountsAndAccess(),
  [IconTypes.ARROW_UP_RIGHT]: ArrowUpRight(),
  [IconTypes.BOOK]: <BookIcon />,
  [IconTypes.CALENDAR]: Calendar(),
  [IconTypes.CHECKMARK]: Checkmark(),
  [IconTypes.CHEVRON_DOWN]: ChevronDown(),
  [IconTypes.CHEVRON_RIGHT]: ChevronRight(),
  [IconTypes.CLOSE_SMALL]: CloseSmall(),
  [IconTypes.CODE_CHANGES]: CodeChanges(),
  [IconTypes.COLUMNS]: Columns(),
  [IconTypes.COMPUTE]: Compute(),
  [IconTypes.COPY]: Copy(),
  [IconTypes.DANGER]: Danger(),
  [IconTypes.DATAFLOW]: Dataflow(),
  [IconTypes.DATA_QUALITY]: DataQuality(),
  [IconTypes.DATASET]: Dataset(),
  [IconTypes.DEFAULT_SERVER_GROUP]: DefaultServerGroup(),
  [IconTypes.DOCUMENTATION]: Documentation(),
  [IconTypes.DRAWER_HIDE]: DrawerHide(),
  [IconTypes.DRAWER_SHOW]: DrawerShow(),
  [IconTypes.EDIT]: Edit(),
  [IconTypes.ENTITIES]: Entities(),
  [IconTypes.EYE]: Eye(),
  [IconTypes.FEATURE_VIEWS]: FeatureView(),
  [IconTypes.FEATURE_SERVICE]: FeatureService(),
  [IconTypes.FEATURE_SERVER_GROUP]: FeatureServerGroup(),
  [IconTypes.FILTERS]: Filters(),
  [IconTypes.FOLDER]: Folder(),
  [IconTypes.GROUP]: Group(),
  [IconTypes.LOGO]: Logo(),
  [IconTypes.KEY_CHAIN]: KeyChain(),
  [IconTypes.LOGOUT]: LogOut(),
  [IconTypes.INFORMATION]: Information(),
  [IconTypes.JOBS]: Jobs(),
  [IconTypes.MAGNIFYING_GLASS]: MagnifyingGlass(),
  [IconTypes.MONITORING]: Monitoring(),
  [IconTypes.PERMISSIONS]: PermissionsIcon(),
  [IconTypes.ROBOT]: Robot(),
  [IconTypes.SOURCES]: Sources(),
  [IconTypes.SUPPORT]: Support(),
  [IconTypes.SUMMARY]: <SummaryViewIcon />,
  [IconTypes.TAG]: Tag(),
  [IconTypes.TRANSFORMATIONS]: Transformations(),
  [IconTypes.TRANSFORM_SERVER_GROUP]: TransformServerGroup(),
  [IconTypes.TRASH]: Trash(),
  [IconTypes.WARNING]: Warning(),
  [IconTypes.WHATS_NEW]: WhatsNew(),
  [IconTypes.WORDMARK]: Wordmark(),
  [IconTypes.WORKSPACES]: Workspaces(),
  [IconTypes.EXIT]: Exit(),
  [IconTypes.EMBEDDING_MODEL]: <EmbeddingModelIcon />,
  [IconTypes.EMBEDDING_CUSTOM_MODEL]: <EmbeddingCustomModelIcon />,
  [IconTypes.AGGREGATION]: <Aggregation />,
  [IconTypes.SELECTED_ARROW]: <SelectedArrow />,
  [IconTypes.LIGHTBULB]: <LightBulb />,
};

export default Icons;
