import React, { FC } from 'react';
import { Badge, Code, FlexGroupWrapper, FlexItem, Link } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

const StepWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.m};
`;

const StyledText = styled.span`
  font-size: ${({ theme }) => theme.font.headingSizes['4']};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']};
  display: contents;
`;

const HowToCreateWorkspace: FC = () => {
  return (
    <>
      <FlexGroupWrapper direction="column" gap={'l'}>
        <FlexItem grow={false}>
          <div>
            Follow these instructions to create your first workspace in Tecton. This will be a{' '}
            <StyledText>development workspace</StyledText>, which is appropriate for learning and exploration in Tecton.
            See our documentation on{' '}
            <Link href="https://docs.tecton.ai/docs/sdk-reference/workspaces" target="_blank">
              Workspaces
            </Link>{' '}
            for more information.
          </div>
        </FlexItem>
        <FlexItem grow={false}>
          The workspace that you create will be empty. You can add workspace objects, such as data sources and feature
          views.
        </FlexItem>
        <FlexItem grow={false}>
          <StepWrapper>
            <FlexGroupWrapper gap={'m'}>
              <FlexItem grow={false}>
                <div>
                  <StyledText>
                    <Badge label="Step 1" /> <>Install the Tecton CLI</>
                  </StyledText>
                </div>
              </FlexItem>
              <FlexItem grow={false}>
                <div>To install the CLI, open a terminal in your desired directory and run the following command:</div>
                <Code language="bash" code={`pip install tecton`} />
              </FlexItem>
              <FlexItem grow={false}>
                <div>
                  Note, you will need to make sure that you have an appropriate Python environment installed locally.
                  See{' '}
                  <Link href="https://docs.tecton.ai/docs/setting-up-tecton/development-setup" target="_blank">
                    Tecton CLI Setup
                  </Link>{' '}
                  documentation.
                </div>
              </FlexItem>
            </FlexGroupWrapper>
          </StepWrapper>
        </FlexItem>
        <FlexItem grow={false}>
          <StepWrapper>
            <FlexGroupWrapper gap={'m'}>
              <FlexItem grow={false}>
                <div>
                  <StyledText>
                    <Badge label="Step 2" /> <>Log in to the CLI</>
                  </StyledText>
                </div>
              </FlexItem>
              <FlexItem grow={false}>
                <div>To log in to Tecton, run the command:</div>
                <Code language="bash" code={`tecton login`} />
              </FlexItem>
            </FlexGroupWrapper>
          </StepWrapper>
        </FlexItem>
        <FlexItem grow={false}>
          <StepWrapper>
            <FlexGroupWrapper gap={'m'}>
              <FlexItem grow={false}>
                <div>
                  <StyledText>
                    <Badge label="Step 3" /> <>Create a Workspace</>
                  </StyledText>
                </div>
              </FlexItem>
              <FlexItem grow={false}>
                <div>To create your first workspace, run the command: </div>
                <Code language="bash" code={`tecton workspace create my-workspace`} />
              </FlexItem>
            </FlexGroupWrapper>
          </StepWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default HowToCreateWorkspace;
