/* eslint-disable */
import { Duration } from "../../google/protobuf/duration";
import { AmplitudeEvent } from "../amplitude/amplitude";
import { SDKMethodInvocation } from "../amplitude/client_logging";
import { RiftClusterConfig } from "../args/feature_view";
import { PrincipalBasic, UserBasic } from "../auth/principal";
import { AwsCredentials } from "../common/aws_credentials";
import { FcoLocator } from "../common/fco_locator";
import { Id } from "../common/id";
import { SparkSchema } from "../common/spark_schema";
import { ConsumptionRecord, ConsumptionType } from "../consumption/consumption";
import { Entity } from "../data/entity";
import { FcoContainer } from "../data/fco";
import { FeatureService } from "../data/feature_service";
import { FeatureView } from "../data/feature_view";
import { FreshnessStatus } from "../data/freshness_status";
import { ListHiveResult } from "../data/hive_metastore";
import { InternalSparkClusterStatus } from "../data/internal_spark_cluster_status";
import { WorkspaceMaterializationRolesAllowlist } from "../data/materialization_roles_allowlists";
import {
  MaterializationAttemptStatus,
  MaterializationStatus,
  MaterializationStatusState,
} from "../data/materialization_status";
import { DataPlatformSetupTaskStatus, OnboardingStatusEnum } from "../data/onboarding";
import { ResourceProvider } from "../data/resource_provider";
import { SavedFeatureDataFrame } from "../data/saved_feature_data_frame";
import { ServiceAccountCredentialsType } from "../data/service_account";
import { FullFeatureServiceServingSummary, ServingStatusSummary } from "../data/serving_status";
import {
  FcoDiff,
  PlanIntegrationTestConfig,
  StateUpdateEntry,
  StateUpdatePlanSummary,
  StateUpdateRequest,
  SuccessfulPlanOutput,
  ValidationResult,
} from "../data/state_update";
import { FcoSummary, SummaryItem } from "../data/summary";
import { TectonApiKeyDto } from "../data/tecton_api_key_dto";
import { Transformation } from "../data/transformation";
import { User } from "../data/user";
import { UserDeploymentSettings } from "../data/user_deployment_settings";
import { VirtualDataSource } from "../data/virtual_data_source";
import { Workspace, WorkspaceCapabilities } from "../data/workspace";
import { FeatureExpectation, MetricExpectation } from "../dataobs/expectation";
import { Metric, MetricDataPoint, MetricType } from "../dataobs/metric";
import { ExpectationResult, ExpectationResultEnum, WorkspaceResultSummary } from "../dataobs/validation";
import { FeatureSimilarityAnalysisResult } from "../feature_analytics/feature_analytics";
import { TectonManagedInfo } from "../materialization/job_metadata";
import { TaskType, TaskTypeForDisplay } from "../materialization/spark_cluster";
import { ValidationRequest } from "../validation/validator";

export const protobufPackage = "tecton_proto.metadataservice";

export enum SortDirection {
  /** SORT_UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  SORT_UNKNOWN = "SORT_UNKNOWN",
  SORT_ASC = "SORT_ASC",
  SORT_DESC = "SORT_DESC",
}

export enum MaterializationEnabledSearchFilter {
  MATERIALIZATION_ENABLED_SEARCH_FILTER_UNSPECIFIED = "MATERIALIZATION_ENABLED_SEARCH_FILTER_UNSPECIFIED",
  MATERIALIZATION_ENABLED_SEARCH_FILTER_DISABLED = "MATERIALIZATION_ENABLED_SEARCH_FILTER_DISABLED",
  MATERIALIZATION_ENABLED_SEARCH_FILTER_ENABLED = "MATERIALIZATION_ENABLED_SEARCH_FILTER_ENABLED",
}

export enum WorkspaceCapabilitiesFilter {
  WORKSPACE_CAPABILITIES_FILTER_UNSPECIFIED = "WORKSPACE_CAPABILITIES_FILTER_UNSPECIFIED",
  WORKSPACE_CAPABILITIES_FILTER_DEV = "WORKSPACE_CAPABILITIES_FILTER_DEV",
  WORKSPACE_CAPABILITIES_FILTER_LIVE = "WORKSPACE_CAPABILITIES_FILTER_LIVE",
}

export enum FcoType {
  FCO_TYPE_UNSPECIFIED = "FCO_TYPE_UNSPECIFIED",
  FCO_TYPE_VIRTUAL_DATA_SOURCE = "FCO_TYPE_VIRTUAL_DATA_SOURCE",
  FCO_TYPE_ENTITY = "FCO_TYPE_ENTITY",
  FCO_TYPE_TRANSFORMATION = "FCO_TYPE_TRANSFORMATION",
  FCO_TYPE_FEATURE_VIEW = "FCO_TYPE_FEATURE_VIEW",
  FCO_TYPE_FEATURE_SERVICE = "FCO_TYPE_FEATURE_SERVICE",
}

export enum FilterField {
  FILTER_FIELD_UNSPECIFIED = "FILTER_FIELD_UNSPECIFIED",
  FILTER_FIELD_FCO_TYPE = "FILTER_FIELD_FCO_TYPE",
  FILTER_FIELD_WORKSPACE = "FILTER_FIELD_WORKSPACE",
  FILTER_FIELD_OWNER = "FILTER_FIELD_OWNER",
  FILTER_FIELD_MATERIALIZATION_OFFLINE = "FILTER_FIELD_MATERIALIZATION_OFFLINE",
  FILTER_FIELD_MATERIALIZATION_ONLINE = "FILTER_FIELD_MATERIALIZATION_ONLINE",
  FILTER_FIELD_WORKSPACE_STATUS = "FILTER_FIELD_WORKSPACE_STATUS",
  FILTER_FIELD_SEARCH_TYPE = "FILTER_FIELD_SEARCH_TYPE",
}

export interface JobsKeySet {
  updated_at?: string | undefined;
  comparison?: string | undefined;
  id?: Id | undefined;
}

export interface PaginationRequest {
  page?: number | undefined;
  per_page?: number | undefined;
  sort_key?: string | undefined;
  sort_direction?: SortDirection | undefined;
  page_token?: string | undefined;
}

export interface PaginationResponse {
  page?: number | undefined;
  per_page?: number | undefined;
  total?: number | undefined;
  next_page_token?: string | undefined;
  sort_key?: string | undefined;
  sort_direction?: SortDirection | undefined;
}

export interface ValidationResultToken {
  validation_time?: string | undefined;
  result_id?: Id | undefined;
  expectation_name?: string | undefined;
}

export interface GetFeatureServiceRequest {
  /** TODO: rename to 'name' (only pass name here) */
  service_reference?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** Fetching by ids supported for debugging only */
  id?:
    | Id
    | undefined;
  /** See GetFeatureViewRequest.run_object_version_check */
  run_object_version_check?: boolean | undefined;
}

export interface GetFeatureServiceResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetAllFeatureServicesRequest {
  workspace?: string | undefined;
}

export interface GetAllFeatureServicesResponse {
  feature_services?: FeatureService[] | undefined;
}

export interface GetFeatureServiceSummaryRequest {
  feature_service_id?: Id | undefined;
  feature_service_name?: string | undefined;
  workspace?: string | undefined;
}

export interface GetFeatureServiceSummaryResponse {
  general_items?: SummaryItem[] | undefined;
  variant_names?: string[] | undefined;
}

export interface GetVirtualDataSourceSummaryRequest {
  /**
   * NOTE: This RPC method uses workspace specified in FcoLocator
   * instead of request headers.
   */
  fco_locator?: FcoLocator | undefined;
}

export interface GetVirtualDataSourceSummaryResponse {
  fco_summary?: FcoSummary | undefined;
}

export interface GetTransformationSummaryRequest {
  /**
   * NOTE: This RPC method uses workspace specified in FcoLocator
   * instead of request headers.
   */
  fco_locator?: FcoLocator | undefined;
}

export interface GetTransformationSummaryResponse {
  fco_summary?: FcoSummary | undefined;
}

export interface GetEntitySummaryRequest {
  /**
   * NOTE: This RPC method uses workspace specified in FcoLocator
   * instead of request headers.
   */
  fco_locator?: FcoLocator | undefined;
}

export interface GetEntitySummaryResponse {
  fco_summary?: FcoSummary | undefined;
}

export interface GetServingStatusRequest {
  /** TODO(amargvela): Rename this when we force the min SDK version update. */
  feature_package_id?: Id | undefined;
  feature_service_id?: Id | undefined;
  workspace?: string | undefined;
}

export interface GetFVServingStatusForFSRequest {
  /** protolint:disable:this FIELDS_AVOID_REQUIRED */
  feature_service_id?: Id | undefined;
  workspace?: string | undefined;
  pagination?: PaginationRequest | undefined;
}

export interface GetFVServingStatusForFSResponse {
  full_serving_status_summary?: FullFeatureServiceServingSummary | undefined;
  pagination?: PaginationResponse | undefined;
}

export interface GetServingStatusResponse {
  serving_status_summary?: ServingStatusSummary | undefined;
}

export interface GetAllFeatureFreshnessRequest {
  workspace?: string | undefined;
}

export interface GetAllFeatureFreshnessResponse {
  freshness_statuses?: FreshnessStatus[] | undefined;
}

export interface GetFeatureFreshnessRequest {
  fco_locator?: FcoLocator | undefined;
}

export interface GetFeatureFreshnessResponse {
  freshness_status?: FreshnessStatus | undefined;
}

export interface GetMaterializationStatusRequest {
  /** TODO(amargvela): Rename this when we force the min SDK version update. */
  feature_package_id?: Id | undefined;
  workspace?: string | undefined;
  include_deleted?: boolean | undefined;
}

export interface GetMaterializationStatusResponse {
  materialization_status?: MaterializationStatus | undefined;
}

export interface GetJobStatusRequest {
  workspace?: string | undefined;
  task_id?: Id | undefined;
}

export interface GetJobStatusResponse {
  status?: MaterializationStatus | undefined;
}

export interface GetAllMaterializationStatusInLiveWorkspacesRequest {
  cut_off_days?: number | undefined;
}

export interface CountRange {
  start?: number | undefined;
  end?: number | undefined;
}

export interface DurationRange {
  start?: Duration | undefined;
  end?: Duration | undefined;
}

export interface DateTimeRange {
  start?: string | undefined;
  end?: string | undefined;
}

export interface GetJobsRequest {
  /** Filters for jobs */
  workspaces?: string[] | undefined;
  feature_views?:
    | string[]
    | undefined;
  /** dataset generation job can be attached to a feature service instead of feature view */
  feature_services?:
    | string[]
    | undefined;
  /** filter jobs by FCO: get only jobs attached to a feature service or a feature view */
  fco_type?: GetJobsRequest_FCOTypeFilter | undefined;
  statuses?:
    | MaterializationStatusState[]
    | undefined;
  /** Field is required, and will default to a small window, if OOM will add better error handling. */
  last_task_state_change?:
    | DateTimeRange
    | undefined;
  /**
   * These filters require pulling all tasks in created_at period`
   * TODO(samantha): Deprecate task_type in favor of task_type_for_display
   */
  task_type?: TaskType[] | undefined;
  task_type_for_displays?: TaskTypeForDisplay[] | undefined;
  num_attempts?:
    | CountRange
    | undefined;
  /** If missing then include both manual and scheduled tasks */
  manually_triggered?: boolean | undefined;
  duration?:
    | DurationRange
    | undefined;
  /** TODO: These filters should be moved to the db query */
  feature_start_time?: DateTimeRange | undefined;
  feature_end_time?:
    | DateTimeRange
    | undefined;
  /** check if client is allowed to Cancel, Retry, Restart materialization */
  include_update_materialization_flags?: boolean | undefined;
  writes_online?: boolean | undefined;
  writes_offline?:
    | boolean
    | undefined;
  /**
   * sort_keys include:
   * (num_attempts, latest_duration, feature_start_time, feature_end_time) ->
   * Require pulling all Tasks in updated_at period (updated_at (DEFAULT)) ->
   * Doesn't require pulling all Tasks, unless filters require it.
   */
  pagination?: PaginationRequest | undefined;
}

export enum GetJobsRequest_FCOTypeFilter {
  /** UNSPECIFIED - no filtering by default */
  UNSPECIFIED = "UNSPECIFIED",
  FEATURE_VIEW_ONLY = "FEATURE_VIEW_ONLY",
  FEATURE_SERVICE_ONLY = "FEATURE_SERVICE_ONLY",
}

export interface GetJobDetailsRequest {
  tecton_managed_attempt_id?: Id | undefined;
}

export interface GetJobLogsRequest {
  tecton_managed_attempt_id?: Id | undefined;
}

export interface FeatureViewMaterializationStatus {
  fco_locator?: FcoLocator | undefined;
  materialization_status?: MaterializationStatus | undefined;
}

export interface TaskWithAttempts {
  fco_locator?:
    | FcoLocator
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  taskState?: MaterializationStatusState | undefined;
  materialization_status?: MaterializationStatus | undefined;
  task_id?: Id | undefined;
  task_type?: TaskType | undefined;
  task_type_for_display?: TaskTypeForDisplay | undefined;
  manually_triggered?: boolean | undefined;
  last_task_state_change?:
    | string
    | undefined;
  /**
   * either feature_view_name or feature_service_name will be set,
   * depending on which FCO the job was created
   */
  feature_view_name?: string | undefined;
  feature_service_name?: string | undefined;
  feature_start_time?: string | undefined;
  feature_end_time?:
    | string
    | undefined;
  /** Only present for plan integration tests */
  plan_id?: Id | undefined;
}

export interface TaskAttemptDetails {
  task_id?: Id | undefined;
  task_type?: TaskType | undefined;
  task_type_for_display?: TaskTypeForDisplay | undefined;
  task_state?: MaterializationStatusState | undefined;
  attempt_status?: MaterializationAttemptStatus | undefined;
  run_details?: TectonManagedInfo | undefined;
  cluster_config?: RiftClusterConfig | undefined;
  feature_start_time?: string | undefined;
  feature_end_time?: string | undefined;
  anyscale_url?: string | undefined;
}

export interface GetAllMaterializationStatusInLiveWorkspacesResponse {
  /** protolint:disable:next REPEATED_FIELD_NAMES_PLURALIZED */
  feature_view_materialization_status?: FeatureViewMaterializationStatus[] | undefined;
}

export interface GetJobsResponse {
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  tasksWithAttempts?: TaskWithAttempts[] | undefined;
  pagination?: PaginationResponse | undefined;
}

export interface GetJobDetailsResponse {
  fco_locator?: FcoLocator | undefined;
  attempt_details?: TaskAttemptDetails | undefined;
}

export interface GetJobLogsResponse {
  fco_locator?:
    | FcoLocator
    | undefined;
  /** newline-separated list of log statements in the form of `[timestamp] message\n` */
  logs?: string | undefined;
}

export interface ForceRetryMaterializationTaskRequest {
  materialization_task_id?: Id | undefined;
  allow_overwrite?: boolean | undefined;
}

export interface ForceRetryMaterializationTaskResponse {
  error_message?: string | undefined;
}

export interface RestartMaterializationTaskRequest {
  materialization_task_id?: Id | undefined;
}

export interface RestartMaterializationTaskResponse {
  error_message?: string | undefined;
  new_materialization_task_id?: Id | undefined;
}

export interface GetFcosRequest {
  workspace?: string | undefined;
}

export interface GetFcosResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetSparkConfigRequest {
  workspace?: string | undefined;
  feature_view_name?: string | undefined;
}

export interface SparkClusterConfig {
  /** customer provided original configuration */
  original?:
    | string
    | undefined;
  /** tecton processed final configuration */
  final?: string | undefined;
}

export interface GetSparkConfigResponse {
  batch_config?: SparkClusterConfig | undefined;
  stream_config?: SparkClusterConfig | undefined;
}

export interface GetMetricAndExpectationDefinitionRequest {
  /** Required. */
  workspace?:
    | string
    | undefined;
  /** Required. */
  feature_view_name?: string | undefined;
}

export interface GetMetricAndExpectationDefinitionResponse {
  workspace?: string | undefined;
  feature_view_name?: string | undefined;
  metrics?: Metric[] | undefined;
  feature_expectations?: FeatureExpectation[] | undefined;
  metric_expectations?: MetricExpectation[] | undefined;
}

export interface GetFeatureViewRequest {
  /** TODO: rename to 'name' (only pass name here) */
  version_specifier?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** Fetching by ids supported for debugging only */
  id?:
    | Id
    | undefined;
  /**
   * If true, the server will return an error if the requested tecton object(s)
   * are from a workspace applied with a more recent version of the SDK, e.g. a
   * 0.6.x client requesting a 0.7.x-applied object. Note that the reverse is
   * almost always supported, e.g. a 0.7.x client should almost always be able
   * to use a 0.6.x-applied object.
   */
  run_object_version_check?: boolean | undefined;
}

export interface GetFeatureViewResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetFeatureViewSummaryRequest {
  /**
   * NOTE: This RPC method uses workspace specified in FcoLocator
   * instead of request headers.
   */
  fco_locator?: FcoLocator | undefined;
}

export interface GetFeatureViewSummaryResponse {
  fco_summary?: FcoSummary | undefined;
}

export interface QueryFeatureViewsRequest {
  /** Only return FV with specified name (all variants) */
  name?: string | undefined;
  workspace?: string | undefined;
}

export interface QueryFeatureViewsResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetMaterializingFeatureViewsInLiveWorkspacesResponse {
  feature_views?: FeatureView[] | undefined;
}

export interface GetVirtualDataSourceRequest {
  virtual_data_source_id?:
    | Id
    | undefined;
  /**
   * If requested by the VDS name, returns unique VirtualDataSource with
   * passed name.
   */
  name?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** See GetFeatureViewRequest.run_object_version_check */
  run_object_version_check?: boolean | undefined;
}

export interface GetVirtualDataSourceResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetAllVirtualDataSourcesRequest {
  workspace?: string | undefined;
}

export interface GetAllVirtualDataSourcesResponse {
  virtual_data_sources?: VirtualDataSource[] | undefined;
}

export interface GetEntityRequest {
  entity_id?:
    | Id
    | undefined;
  /** Unique name of an entity */
  name?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** See GetFeatureViewRequest.run_object_version_check */
  run_object_version_check?: boolean | undefined;
}

export interface GetEntityResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetAllEntitiesRequest {
  workspace?: string | undefined;
}

export interface GetAllEntitiesResponse {
  entities?: Entity[] | undefined;
}

export interface GetTransformationRequest {
  transformation_id?: Id | undefined;
  name?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** See GetFeatureViewRequest.run_object_version_check */
  run_object_version_check?: boolean | undefined;
}

export interface GetTransformationResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetAllTransformationsRequest {
  workspace?: string | undefined;
}

export interface GetAllTransformationsResponse {
  transformations?: Transformation[] | undefined;
}

export interface GetResourceProviderRequest {
  resource_provider_id?:
    | Id
    | undefined;
  /** Unique name of a resource provider. */
  name?: string | undefined;
  workspace?: string | undefined;
  run_object_version_check?: boolean | undefined;
}

export interface GetResourceProviderResponse {
  fco_container?: FcoContainer | undefined;
}

export interface GetAllResourceProvidersRequest {
  workspace?: string | undefined;
}

export interface GetAllResourceProvidersResponse {
  resource_providers?: ResourceProvider[] | undefined;
}

export interface FindFcoWorkspaceRequest {
  feature_view_id?: Id | undefined;
}

export interface FindFcoWorkspaceResponse {
  workspace?: string | undefined;
}

/**
 * If adding any fields here, please update the new field to be in or omitted from the
 * corresponding field mask in [[RequestResponseLoggingServerInterceptor.kt]]
 */
export interface IngestClientLogsRequest {
  sdk_method_invocation?: SDKMethodInvocation | undefined;
}

export interface IngestAnalyticsRequest {
  events?: AmplitudeEvent[] | undefined;
  workspace?: string | undefined;
}

export interface NewStateUpdateRequest {
  request?:
    | StateUpdateRequest
    | undefined;
  /**
   * A debug-only param that will make plan block indefinitely before returning
   * an eager response, and not write anything to Metadata DB. Set to true when
   * tecton --debug plan was called
   */
  blocking_dry_run_mode?:
    | boolean
    | undefined;
  /**
   * If set, the backend will block for a small amount of time, and the plan
   * will be returned synchronously if it becomes available during that time.
   * Superseded by blocking_dry_run_mode
   */
  enable_eager_response?: boolean | undefined;
}

export interface NewStateUpdateRequestV2 {
  request?:
    | StateUpdateRequest
    | undefined;
  /**
   * A debug-only param that will make plan block indefinitely before returning
   * an eager response
   */
  blocking_dry_run_mode?:
    | boolean
    | undefined;
  /**
   * If set, the backend will block for a small amount of time, and the plan
   * will be returned synchronously if it becomes available during that time.
   * Superseded by blocking_dry_run_mode
   */
  enable_eager_response?:
    | boolean
    | undefined;
  /**
   * If true, the plan output string returned will not have coloring (ie no ANSI
   * escape codes)
   */
  no_color?:
    | boolean
    | undefined;
  /** If true, the JSON plan output will also be returned in a separate field */
  json_output?:
    | boolean
    | undefined;
  /** If true, do not return plan warnings. */
  suppress_warnings?: boolean | undefined;
}

export interface NewStateUpdateResponse {
  state_id?: Id | undefined;
  signed_url_for_repo_upload?:
    | string
    | undefined;
  /**
   * Full plan response, which will be always be available if
   * blocking_dry_run_mode was set, or may be available if enable_eager_response
   * was set and the plan became available in time.
   */
  eager_response?: QueryStateUpdateResponse | undefined;
}

export interface NewStateUpdateResponseV2 {
  state_id?: Id | undefined;
  signed_url_for_repo_upload?:
    | string
    | undefined;
  /**
   * Full plan response, which will be always be available if
   * blocking_dry_run_mode was set, or may be available if enable_eager_response
   * was set and the plan became available in time.
   */
  eager_response?: QueryStateUpdateResponseV2 | undefined;
}

export interface QueryStateUpdateRequest {
  state_id?: Id | undefined;
  workspace?: string | undefined;
}

export interface QueryStateUpdateRequestV2 {
  state_id?: Id | undefined;
  workspace?:
    | string
    | undefined;
  /**
   * If true, the plan output string returned will not have coloring (ie no ANSI
   * escape codes)
   */
  no_color?:
    | boolean
    | undefined;
  /** If true, the JSON plan output will also be returned in a separate field */
  json_output?:
    | boolean
    | undefined;
  /** If true, do not return plan warnings. */
  suppress_warnings?: boolean | undefined;
}

export interface QueryStateUpdateResponse {
  ready?: boolean | undefined;
  success?: boolean | undefined;
  error?: string | undefined;
  recreates_suppressed?: boolean | undefined;
  validation_result?: ValidationResult | undefined;
  diff_items?:
    | FcoDiff[]
    | undefined;
  /**
   * The latest human-readable status update from the MDS plan validation
   * process.
   */
  latest_status_message?: string | undefined;
  plan_integration_validation_result?: ValidationResult | undefined;
}

export interface QueryStateUpdateResponseV2 {
  ready?: boolean | undefined;
  success?: boolean | undefined;
  error?:
    | string
    | undefined;
  /**
   * The latest human-readable status update from the MDS plan validation
   * process.
   */
  latest_status_message?:
    | string
    | undefined;
  /** ValidationResult but only contains errors, no warnings */
  validation_errors?: ValidationResult | undefined;
  successful_plan_output?:
    | SuccessfulPlanOutput
    | undefined;
  /**
   * Both of these exist because we can't deprecate the first one in the case of
   * old State Updates without applied_by_principal
   */
  applied_by?: string | undefined;
  applied_by_principal?: PrincipalBasic | undefined;
  applied_at?: string | undefined;
  workspace?: string | undefined;
  sdk_version?: string | undefined;
  created_at?: string | undefined;
  created_by?: string | undefined;
}

export interface GetStateUpdatePlanSummaryRequest {
  plan_id?: Id | undefined;
}

export interface GetStateUpdatePlanSummaryResponse {
  plan?: StateUpdatePlanSummary | undefined;
}

export interface ApplyStateUpdateRequest {
  state_id?:
    | Id
    | undefined;
  /** @deprecated */
  applied_by?: string | undefined;
  plan_integration_config?: PlanIntegrationTestConfig | undefined;
}

export interface ApplyStateUpdateResponse {
}

export interface GetConfigsResponse {
  key_values?: { [key: string]: string } | undefined;
}

export interface GetConfigsResponse_KeyValuesEntry {
  key: string;
  value: string;
}

export interface GetGlobalsForWebUIResponse {
  key_values?: { [key: string]: string } | undefined;
}

export interface GetGlobalsForWebUIResponse_KeyValuesEntry {
  key: string;
  value: string;
}

export interface GetStateUpdateLogRequest {
  limit?: number | undefined;
  workspace?: string | undefined;
}

export interface GetStateUpdateLogResponse {
  entries?: StateUpdateEntry[] | undefined;
}

export interface GetStateUpdatePlanListRequest {
  limit?: number | undefined;
  workspace?: string | undefined;
}

export interface GetStateUpdatePlanListResponse {
  entries?: StateUpdateEntry[] | undefined;
}

export interface GetRestoreInfoRequest {
  commit_id?: string | undefined;
  workspace?: string | undefined;
}

export interface GetRestoreInfoResponse {
  signed_url_for_repo_download?: string | undefined;
  commit_id?: string | undefined;
  sdk_version?: string | undefined;
}

export interface CreateWorkspaceRequest {
  workspace_name?: string | undefined;
  capabilities?: WorkspaceCapabilities | undefined;
}

export interface DeleteWorkspaceRequest {
  workspace?: string | undefined;
}

export interface IntrospectApiKeyRequest {
  api_key?: string | undefined;
}

export interface IntrospectApiKeyResponse {
  id?: Id | undefined;
  description?: string | undefined;
  created_by?: string | undefined;
  active?: boolean | undefined;
  is_admin?: boolean | undefined;
  name?: string | undefined;
}

export interface CreateApiKeyRequest {
  description?: string | undefined;
  is_admin?: boolean | undefined;
}

/**
 * There is a field mask defined for CreateApiKeyResponse in
 * RequestResponseLoggingServerInterceptor if you add a new non-sensitive field
 * here, please also update the corresponding field mask
 */
export interface CreateApiKeyResponse {
  key?: string | undefined;
  id?: Id | undefined;
}

export interface DeleteApiKeyRequest {
  id?: Id | undefined;
}

export interface ListApiKeysRequest {
  /** TODO: do we want this? */
  include_archived?: boolean | undefined;
}

export interface ListApiKeysResponse {
  api_keys?: TectonApiKeyDto[] | undefined;
}

export interface ServiceAccount {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  is_active?: boolean | undefined;
  created_by?: PrincipalBasic | undefined;
  created_at?: string | undefined;
  credentials_type?: ServiceAccountCredentialsType | undefined;
}

export interface CreateServiceAccountRequest {
  name?: string | undefined;
  description?: string | undefined;
  credentials_type?: ServiceAccountCredentialsType | undefined;
}

/**
 * There is a field mask defined for CreateApiKeyResponse in
 * RequestResponseLoggingServerInterceptor if you add a new non-sensitive field
 * here, please also update the corresponding field mask
 */
export interface CreateServiceAccountResponse {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  is_active?: boolean | undefined;
  api_key?: string | undefined;
  created_at?: string | undefined;
  credentials_type?: ServiceAccountCredentialsType | undefined;
  client_secret?: string | undefined;
}

export interface GetServiceAccountsRequest {
  search?: string | undefined;
  ids?: string[] | undefined;
}

export interface GetServiceAccountsResponse {
  service_accounts?: ServiceAccount[] | undefined;
}

export interface UpdateServiceAccountRequest {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  is_active?: boolean | undefined;
}

export interface UpdateServiceAccountResponse {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  is_active?: boolean | undefined;
  created_at?: string | undefined;
}

export interface DeleteServiceAccountRequest {
  id?: string | undefined;
}

export interface DeleteServiceAccountResponse {
}

export interface ListWorkspacesRequest {
}

export interface ListWorkspacesResponse {
  workspaces?: Workspace[] | undefined;
}

export interface GetWorkspaceRequest {
  workspace_name?: string | undefined;
}

export interface GetWorkspaceResponse {
  workspace?: Workspace | undefined;
}

export interface CreateSavedFeatureDataFrameRequest {
  name?: string | undefined;
  workspace?:
    | string
    | undefined;
  /** TODO(amargvela): Rename this when we force the min SDK version update. */
  feature_package_id?: Id | undefined;
  feature_service_id?: Id | undefined;
  join_key_column_names?: string[] | undefined;
  timestamp_column_name?: string | undefined;
  schema?: SparkSchema | undefined;
}

export interface CreateSavedFeatureDataFrameResponse {
  saved_feature_dataframe?: SavedFeatureDataFrame | undefined;
}

export interface ArchiveSavedFeatureDataFrameRequest {
  saved_feature_dataframe_id?: Id | undefined;
}

export interface ArchiveSavedFeatureDataFrameResponse {
}

export interface GetSavedFeatureDataFrameRequest {
  saved_feature_dataframe_name?: string | undefined;
  saved_feature_dataframe_id?: Id | undefined;
  workspace?: string | undefined;
}

export interface GetClusterAdminInfoResponse {
  caller_is_admin?: boolean | undefined;
  users?:
    | User[]
    | undefined;
  /**
   * admins is only returned if the caller can list access on the organization
   * (only Admins are)
   */
  admins?: User[] | undefined;
}

export interface CreateClusterUserRequest {
  login_email?: string | undefined;
}

export interface CreateClusterUserResponse {
}

export interface DeleteClusterUserRequest {
  okta_id?: string | undefined;
}

export interface DeleteClusterUserResponse {
}

export interface ClusterUserActionRequest {
  okta_id?: string | undefined;
  resend_activation_email?: boolean | undefined;
  unlock_user?: boolean | undefined;
  grant_admin?: boolean | undefined;
  revoke_admin?: boolean | undefined;
}

export interface ClusterUserActionResponse {
}

export interface GetSavedFeatureDataFrameResponse {
  saved_feature_dataframe?: SavedFeatureDataFrame | undefined;
}

export interface GetAllSavedFeatureDataFramesRequest {
  workspace?: string | undefined;
}

export interface GetAllSavedFeatureDataFramesResponse {
  saved_feature_dataframes?: SavedFeatureDataFrame[] | undefined;
}

export interface IngestDataframeRequest {
  feature_definition_id?: Id | undefined;
  df_path?: string | undefined;
  workspace?: string | undefined;
}

export interface IngestDataframeResponse {
}

export interface GetNewIngestDataframeInfoRequest {
  feature_definition_id?: Id | undefined;
}

export interface GetNewIngestDataframeInfoResponse {
  df_path?: string | undefined;
  signed_url_for_df_upload?: string | undefined;
}

export interface GetUserDeploymentSettingsResponse {
  user_deployment_settings?: UserDeploymentSettings | undefined;
}

export interface UpdateUserDeploymentSettingsRequest {
  user_deployment_settings?:
    | UserDeploymentSettings
    | undefined;
  /**
   * The field mask defines explicitly which fields should be modified by the
   * update call. This is helpful for clearing values and doing patch updates to
   * user deployment settings.
   * https://developers.google.com/protocol-buffers/docs/reference/csharp/class/google/protobuf/well-known-types/field-mask
   */
  field_mask?: string[] | undefined;
}

export interface UpdateUserDeploymentSettingsResponse {
  success?: boolean | undefined;
  error_message?: string | undefined;
}

/** Get the latest internal spark cluster (aka the "validation cluster") status. */
export interface GetInternalSparkClusterStatusResponse {
  status?: InternalSparkClusterStatus | undefined;
}

export interface GetDeleteEntitiesInfoRequest {
  feature_definition_id?: Id | undefined;
}

export interface GetDeleteEntitiesInfoResponse {
  df_path?: string | undefined;
  signed_url_for_df_upload_online?: string | undefined;
  signed_url_for_df_upload_offline?: string | undefined;
}

export interface DeleteEntitiesRequest {
  fco_locator?:
    | FcoLocator
    | undefined;
  /** All files matching the pattern {online_join_keys_path}/part-*.csv will be read */
  online_join_keys_path?:
    | string
    | undefined;
  /** The file exactly matching online_join_keys_full_path will be read */
  online_join_keys_full_path?: string | undefined;
  offline_join_keys_path?: string | undefined;
  online?: boolean | undefined;
  offline?: boolean | undefined;
}

export interface DeleteEntitiesResponse {
  job_ids?: string[] | undefined;
}

export interface GetFeatureAnalyticsRequest {
}

export interface GetFeatureAnalyticsResponse {
  feature_analytics?: FeatureSimilarityAnalysisResult | undefined;
}

export interface GetHiveMetadataRequest {
  action?:
    | GetHiveMetadataRequest_Action
    | undefined;
  /** Used for ACTION_LIST_TABLES and ACTION_GET_TABLE_SCHEMA */
  database?:
    | string
    | undefined;
  /** Used for ACTION_GET_TABLE_SCHEMA */
  table?: string | undefined;
}

export enum GetHiveMetadataRequest_Action {
  /** ACTION_LIST_DATABASES - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  ACTION_LIST_DATABASES = "ACTION_LIST_DATABASES",
}

export interface GetHiveMetadataResponse {
  success?: boolean | undefined;
  error_message?: string | undefined;
  databases?: ListHiveResult | undefined;
  debug_error_message?: string | undefined;
}

export interface ValidateLocalFcoRequest {
  validation_request?: ValidationRequest | undefined;
  sdk_version?: string | undefined;
}

export interface ValidateLocalFcoResponse {
  success?: boolean | undefined;
  validation_result?: ValidationResult | undefined;
  error?: string | undefined;
}

export interface GetOnboardingStatusRequest {
  workspace?: string | undefined;
}

export interface GetOnboardingStatusResponse {
  setup_platform?: OnboardingStatusEnum | undefined;
  finish_onboarding?: OnboardingStatusEnum | undefined;
}

export interface GetDataPlatformSetupStatusResponse {
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  setupCompleted?: boolean | undefined;
  tasks?: DataPlatformSetupTaskStatus[] | undefined;
}

export interface GetObservabilityConfigRequest {
  /** Required. */
  workspace?:
    | string
    | undefined;
  /** Required. */
  feature_view_name?: string | undefined;
}

export interface GetObservabilityConfigResponse {
  workspace?: string | undefined;
  feature_view_name?: string | undefined;
  is_dataobs_metric_enabled?: boolean | undefined;
}

export interface QueryMetricRequest {
  /** Required. */
  workspace?:
    | string
    | undefined;
  /** Required. */
  feature_view_name?:
    | string
    | undefined;
  /** Required. */
  metric_type?:
    | MetricType
    | undefined;
  /** Required. */
  start_time?:
    | string
    | undefined;
  /** Required. */
  end_time?:
    | string
    | undefined;
  /** Optional. Apply only to Batch and Stream Feature Views. Must be > 0. */
  limit?: number | undefined;
}

export interface QueryMetricResponse {
  workspace?: string | undefined;
  feature_view_name?: string | undefined;
  metric_type?: MetricType | undefined;
  metric_data_point_interval?: Duration | undefined;
  aligned_start_time?: string | undefined;
  aligned_end_time?: string | undefined;
  metric_data?: MetricDataPoint[] | undefined;
  column_names?: string[] | undefined;
}

export interface GetFeatureValidationResultRequest {
  /** Required parameters */
  workspace?: string | undefined;
  validation_start_time?: string | undefined;
  validation_end_time?:
    | string
    | undefined;
  /**
   * Optional filters.
   * Keep only results matching any feature view names provided.
   */
  filter_feature_view_names?:
    | string[]
    | undefined;
  /** Keep only results matching any expectation names provided. */
  filter_expectation_names?:
    | string[]
    | undefined;
  /** Keep only results matching any result types provided. */
  filter_result_types?:
    | ExpectationResultEnum[]
    | undefined;
  /**
   * Optional
   * Only pagination by page_token is supported.
   */
  pagination?: PaginationRequest | undefined;
}

export interface GetFeatureValidationResultResponse {
  results?: ExpectationResult[] | undefined;
  pagination?: PaginationResponse | undefined;
}

export interface GetFeatureValidationSummaryRequest {
  workspace?: string | undefined;
  validation_start_time?: string | undefined;
  validation_end_time?: string | undefined;
}

export interface GetFeatureValidationSummaryResponse {
  workspace?: string | undefined;
  validation_start_time?: string | undefined;
  validation_end_time?: string | undefined;
  workspace_summary?: WorkspaceResultSummary | undefined;
}

export interface GetUserRequest {
  id?: string | undefined;
  email?: string | undefined;
}

export interface GetUserResponse {
  user?: UserBasic | undefined;
}

export interface FeatureServerAutoScalingConfig {
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE; */
  enabled?:
    | boolean
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE; */
  min_node_count?:
    | number
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE; */
  max_node_count?: number | undefined;
}

/** Empty proto */
export interface GetFeatureServerConfigRequest {
}

export interface GetFeatureServerConfigResponse {
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  currentCount?:
    | number
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  availableCount?:
    | number
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  desiredCount?:
    | number
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  autoScalingConfig?: FeatureServerAutoScalingConfig | undefined;
}

export interface SetFeatureServerConfigRequest {
  count?:
    | number
    | undefined;
  /** protolint:disable:this FIELD_NAMES_LOWER_SNAKE_CASE */
  autoScalingConfig?: FeatureServerAutoScalingConfig | undefined;
}

export interface GetOfflineStoreCredentialsRequest {
  feature_view_id?: Id | undefined;
  data_source_id?: Id | undefined;
  saved_feature_data_frame_id?: Id | undefined;
}

export interface GetOfflineStoreCredentialsResponse {
  aws?: AwsCredentials | undefined;
}

export interface GlobalSearchRequest {
  text?:
    | string
    | undefined;
  /** Option to search current UI-selected workspace. If empty, we'll assume cross-workspace search */
  current_workspace?: string | undefined;
  fco_type_filters?: FcoType[] | undefined;
  workspace_filters?: string[] | undefined;
  owner_filters?: string[] | undefined;
  materialization_offline_filter?: MaterializationEnabledSearchFilter | undefined;
  materialization_online_filter?: MaterializationEnabledSearchFilter | undefined;
  workspace_live_filter?: WorkspaceCapabilitiesFilter | undefined;
}

export interface GlobalSearchResponse {
  results?: GlobalSearchResult[] | undefined;
}

export interface GlobalSearchResult {
  fco_result?:
    | FcoSearchResult
    | undefined;
  /**
   * in the future we'd be able to add something like this for example
   * PlanSearchResult plan_result;
   */
  filter_result?: FilterSearchResult | undefined;
}

export interface FeatureTags {
  tags?: { [key: string]: string } | undefined;
}

export interface FeatureTags_TagsEntry {
  key: string;
  value: string;
}

export interface FcoSearchResult {
  fco_id?: string | undefined;
  workplace_state_id?: string | undefined;
  fco_type?: FcoType | undefined;
  name?: string | undefined;
  description?: string | undefined;
  owner?: string | undefined;
  tags?: { [key: string]: string } | undefined;
  workspace?: string | undefined;
  last_updated?: string | undefined;
  materialization_offline?: MaterializationEnabledSearchFilter | undefined;
  materialization_online?: MaterializationEnabledSearchFilter | undefined;
  workspace_status?:
    | WorkspaceCapabilitiesFilter
    | undefined;
  /** list of feature names, if Feature_View */
  features?:
    | string[]
    | undefined;
  /** list of feature descriptions, if Feature_view */
  feature_descriptions?:
    | string[]
    | undefined;
  /** list of feature tags, if Feature_view */
  feature_tags?: FeatureTags[] | undefined;
}

export interface FcoSearchResult_TagsEntry {
  key: string;
  value: string;
}

export interface FilterSearchResult {
  result?: string | undefined;
  last_updated?: string | undefined;
}

export interface SuggestGlobalSearchFiltersRequest {
  text?: string | undefined;
  filter_type?: FilterField | undefined;
}

export interface SuggestGlobalSearchFiltersResponse {
  results?: GlobalSearchResult[] | undefined;
}

export interface GetMaterializationRolesAllowlistRequest {
  workspace?: string | undefined;
}

export interface GetMaterializationRolesAllowlistResponse {
  allowlist?: WorkspaceMaterializationRolesAllowlist | undefined;
  global_validation_role?: string | undefined;
}

export interface GetConsumptionRecordsRequest {
  consumption_type?: ConsumptionType | undefined;
  start_time?: string | undefined;
  end_time?: string | undefined;
}

export interface GetConsumptionRecordsResponse {
  consumption_records?: ConsumptionRecord[] | undefined;
}
