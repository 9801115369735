import { Badge, Icons, IconTypes } from '@tecton/ComponentRedesign';
import { FC } from 'react';

export interface FCOTag {
  key: string;
  value: string;
}

interface TectonTagProps {
  tag: FCOTag;
  maxWidth?: string;
}

const FCOTagBadge: FC<TectonTagProps> = ({ tag, maxWidth }) => {
  return (
    <Badge
      size="m"
      icon={Icons[IconTypes.TAG]}
      label={tag.key}
      value={tag.value}
      variant="default"
      maxWidth={maxWidth}
    />
  );
};

export default FCOTagBadge;
