export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3334 7.5V2.91668C11.3334 1.99601 9.24406 1.25 6.66671 1.25C4.08935 1.25 2 1.99601 2 2.91668V10.9167C2 11.7774 3.82587 12.4854 6.16942 12.5741H6.66671"
        stroke="black"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 2.91663C11.3334 3.8373 9.24406 4.58331 6.66671 4.58331C4.08935 4.58331 2 3.8373 2 2.91663"
        stroke="black"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00004 9.91668C4.42269 9.91668 2 9.17067 2 8.25"
        stroke="black"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 5.5835C11.3334 6.50417 9.24406 7.25018 6.66671 7.25018C4.08935 7.25018 2 6.50417 2 5.5835"
        stroke="black"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10.5"
        cy="11.25"
        r="3.5"
        stroke="black"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
