import styled from '@emotion/styled';
import React, { FC } from 'react';

interface MonospaceProps {
  children: React.ReactNode;
}

const Monospace: FC<MonospaceProps> = ({ children }) => {
  const Monostyle = styled.span`
    font-family: Roboto Mono;
    white-space: initial;
    word-break: break-word;
  `;
  return <Monostyle>{children}</Monostyle>;
};

export default Monospace;
