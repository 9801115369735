import { TopBanner } from '@tecton/ComponentRedesign';
import { FC } from 'react';

import { ReactComponent as RocketIcon } from '@svg/rocket.svg';

const ExploreTopBanner: FC<{ onClick: VoidFunction }> = ({ onClick }) => {
  return (
    <>
      <TopBanner
        variant="success"
        bannerLabel="Demo Account"
        description="You are viewing a real-time fraud detection use case"
        primaryText={'Start Tutorial'}
        primaryIcon={RocketIcon}
        primaryUrl={`https://docs.tecton.ai/docs/beta/tutorials/tecton-quick-start`}
        onCloseClicked={onClick}
        animated={false}
      />
    </>
  );
};

export default ExploreTopBanner;
