import { uniq } from 'lodash';
import {
  FCOFields,
  FCOType,
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  ServerGroupItem,
} from '../../core/types/fcoTypes';
import { Fco } from '../../types/tecton_proto/data/fco';
import { FeatureService } from '../../types/tecton_proto/data/feature_service';
import DemoUtils from '../../utils/demo-utils';
import IdUtils from '../../utils/id-utils';
import { getSharedFCOProperties } from './fcoUtils';
import { ServerGroupType } from '../../types/tecton_proto/common/server_group_type';

export const transformFeatureServiceProtoToFeatureServiceFCO: (fco: Fco) => FeatureServiceFCO = (fco) => {
  const rawFeatureService: FeatureService = fco.feature_service!;
  const metadata = rawFeatureService.fco_metadata!;

  const asFeatureServiceFCO: FeatureServiceFCO = {
    ...getSharedFCOProperties(metadata, rawFeatureService.feature_service_id),
    fcoType: FCOType.FEATURE_SERVICE,
    [FeatureServiceFCOFields.ALL_FEATURE_VIEWS]: getAllFeatureViews(rawFeatureService),
    [FeatureServiceFCOFields.SHOULD_HIDE_MATERIALIZATION_STATUS]: false,
    [FeatureServiceFCOFields.IS_ENABLED]: DemoUtils.featureServiceIsEnabled(rawFeatureService.feature_service_id),
    [FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED]: rawFeatureService.online_serving_enabled ?? false,
    [FeatureServiceFCOFields.IS_CACHING_ENABLED]: rawFeatureService.enable_online_caching ?? false,
    [FeatureServiceFCOFields.FEATURE_PARAMETERS]: [],
    [FeatureServiceFCOFields.FEATURE_SET]: getFeatureSet(rawFeatureService),
    [FeatureServiceFCOFields.DEPENDENT_DATA_SOURCES]: DemoUtils.dependentDataSources(),
    [FeatureServiceFCOFields.DEPENDENT_FEATURE_VIEWS]: getAllFeatureViews(rawFeatureService),
    [FeatureServiceFCOFields.FEATURE_SET_ITEMS]: getFeatureSetItems(rawFeatureService),
    [FeatureServiceFCOFields.REALTIME_ENVIRONMENT]: getRealtimeEnvironment(rawFeatureService),
    [FeatureServiceFCOFields.SERVER_GROUPS]: getServerGroups(rawFeatureService),
    [FCOFields.PREVENT_DESTROY]: rawFeatureService.validation_args?.args?.prevent_destroy,
  };

  return asFeatureServiceFCO;
};

// TODO: is this function needed? Seems to be similar to the function below
const getFeatureSet = (featureService: FeatureService) => {
  return {};
};

const getRealtimeEnvironment = (featureService: FeatureService) => {
  return featureService.realtime_environment?.remote_compute?.name;
};

const getAllFeatureViews = (featureService: FeatureService) => {
  return (
    uniq(
      featureService.feature_set_items?.map((featureSetItem) => {
        return IdUtils.toStringId(featureSetItem.feature_view_id);
      })
    ) || []
  );
};

const getFeatureSetItems = (featureService: FeatureService) => {
  return featureService?.feature_set_items ?? [];
};

const getServerGroups = (featureService: FeatureService): ServerGroupItem[] => {
  if (!featureService.transform_server_group && !featureService.feature_server_group)
    return [
      {
        type: ServerGroupType.SERVER_GROUP_TYPE_UNSPECIFIED,
      },
    ];

  return [
    ...(featureService.transform_server_group
      ? [
          {
            type: ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP,
            id: IdUtils.toStringId(featureService.transform_server_group.server_group_id),
          },
        ]
      : []),
    ...(featureService.feature_server_group
      ? [
          {
            type: ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP,
            id: IdUtils.toStringId(featureService.feature_server_group.server_group_id),
          },
        ]
      : []),
  ];
};
