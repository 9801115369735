export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="3.66667"
        cy="3.66667"
        r="1.66667"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.3334"
        cy="12.3334"
        r="1.66667"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33325 3.66675H10.9999C11.7363 3.66675 12.3333 4.2637 12.3333 5.00008V10.6667"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99992 5.33333L7.33325 3.66667L8.99992 2"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66675 12.3333H5.00008C4.2637 12.3333 3.66675 11.7363 3.66675 10.9999V5.33325"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.6667L8.66667 12.3334L7 14.0001"
        stroke="#6D7587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
