export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8333 9V2.79167C10.8333 1.871 8.744 1.125 6.16667 1.125C3.58933 1.125 1.5 1.871 1.5 2.79167V10.7917C1.5 11.5684 2.98723 12.2209 5 12.4058"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 2.79163C10.8333 3.71229 8.744 4.45829 6.16667 4.45829C3.58933 4.45829 1.5 3.71229 1.5 2.79163"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 8.125C10.8333 8.34408 10.715 8.55326 10.5 8.74486M6 9.79167C3.42267 9.79167 1.5 9.04567 1.5 8.125"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 11.3746L8.99206 14.875L14.5 11.3746L9.00794 7.875L3.5 11.3746Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 5.45837C10.8333 6.37904 8.744 7.12504 6.16667 7.12504C3.58933 7.12504 1.5 6.37904 1.5 5.45837"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
