export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_369_1447)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33331 12C3.33331 12.9207 5.42265 13.6667 7.99998 13.6667C10.5773 13.6667 12.6666 12.9207 12.6666 12V4.00004C12.6666 3.07937 10.5773 2.33337 7.99998 2.33337C5.42265 2.33337 3.33331 3.07937 3.33331 4.00004V12Z"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6666 4C12.6666 4.92067 10.5773 5.66667 7.99998 5.66667C5.42265 5.66667 3.33331 4.92067 3.33331 4"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6666 9.33337C12.6666 10.254 10.5773 11 7.99998 11C5.42265 11 3.33331 10.254 3.33331 9.33337"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6666 6.66675C12.6666 7.58741 10.5773 8.33341 7.99998 8.33341C5.42265 8.33341 3.33331 7.58741 3.33331 6.66675"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_369_1447">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
